<template>

    <v-app id="bg_seven">
        <div id="layout_top_seven">
            
        </div>
        <div class="seven_content d-flex flex-column justify-space-between align-center pa-10">
            <v-row align="stretch">
                <v-col cols="12" class="d-flex flex-column align-center pt-10">
                    <h3 style="color:white;" class="text-center mt-10 t_soal">{{ questions.text }}</h3>

                    <b-button v-for="(response, index) in questions.responses" 
                        @click="saveAnswer(index)"
                        :key="index"
                        pill
                        block
                        size="lg"
                        class="mt-5 shadow"
                        style="background-color:white;height:45px; font-size:10px;border-color:white;color:#FFB61D;">
                        <span class="text_btn">
                            {{ response.text}}
                        </span>
                    </b-button>
                </v-col>
            </v-row>
        </div>
    </v-app>
</template>

<style>
    @font-face {
        font-family: Poppins;
        src: url('../../assets/fonts/Poppins-Medium.ttf');
    }
    #bg_seven {
        width: 100%;
        height: 100%;
        background: #FFB61D;
    }
    .seven_content{
        z-index:6;
        margin-top:90px;
    }
    .t_soal{
        font-weight: 700;
        font-style: normal;
        font-size: 18px;
    }
    .t_result{
        font-weight: 400;
        font-style: italic;
        font-size:9px;
    }
    .text_btn{
        font-weight: 600;
        font-style: normal;
        font-size:12px;
    }
    #layout_top_seven {
        position: fixed;
        width: 100%;
        height: 100%;
        background: url('../../assets/images/bg.png') no-repeat;
        background-size: 100%;  
    }
    @media (min-width : 1000px) {
        #layout_top_seven{
            width : 100%;
            height : 100%;
            position :fixed;
        }
    }
</style>
<script>
export default {
  name: 'QuestionSeven',
  data: () => ({
     questions: {
        text: "Seberapa sering kamu pergi ke dokter gigi ?",
        responses: [
            { text: "Saya rutin buat janji dengan dokter gigi saya" },
            { text: "2 kali / tahun" },
            { text: "Saya musuhan sama dokter gigi" }
        ]
     }
  }),
  methods: {
    saveAnswer (index) {
        localStorage.setItem('7', index)
        this.$router.push({ name: 'SubmitQuestion'})
    },
    changeColor(){
        var metaThemeColor = document.querySelector("meta[name=theme-color]");
            metaThemeColor.setAttribute("content", "#FFB61D");
    }
    },
    created() {
        this.changeColor();
    },
}
</script>
